import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    routes: [
        // {
        //     path: "/error",
        //     name: "error",
        //     component: () => import("@/view/pages/error/Error.vue"),
        //     children: [
        //         {
        //             path: "error-1",
        //             name: "error-1",
        //             component: () => import("@/view/pages/error/Error-1.vue")
        //         },
        //     ]
        // },
        {
            path: "/",
            component: () => import("@/view/pages/Base"),
            children: [
                {
                    path: "/",
                    name: 'index',
                    component: () => import("@/view/front-end/Index"),
                },
                {
                    path: "/admission",
                    component: () => import("@/view/front-end/Base"),
                    children: [
                        {
                            path: "/admission",
                            name: 'pre-registration',
                            component: () => import("@/view/front-end/pre-registration/Index")
                        },

                    ],

                },
                {
                    path: "/enrollment",
                    name: 'enrollment',
                    component: () => import("@/view/front-end/enrollment/Enrollment"),
                },
                {
                    path: "/success",
                    name: 'success',
                    component: () => import("@/view/front-end/pre-registration/Success"),
                },
                {
                    path: "/payment-success",
                    name: 'payment-success',
                    component: () => import("@/view/front-end/pre-registration/PaymentSuccess"),
                },
                {
                    path: "/error",
                    name: 'error',
                    component: () => import("@/view/front-end/pre-registration/Error"),
                },
                {
                    path: "/saved",
                    name: 'saved',
                    component: () => import("@/view/front-end/pre-registration/Saved"),
                },
                // {
                //     path: "/pre-registration",
                //     name: 'pre-registration',
                //     component: () => import("@/view/front-end/pre-registration/Index"),
                //     // children: [
                //     //     {
                //     //         path: "/pre-registration/history",
                //     //         name: 'pre-registration-history',
                //     //         component: () => import("@/view/pages/pre-registration/Index")
                //     //     },
                //     //     {
                //     //         path: "/pre-registration/new",
                //     //         name: 'pre-registration-new',
                //     //         component: () => import("@/view/pages/pre-registration/CreateOrUpdate")
                //     //     },
                //     // ],
                // },

                {
                    path: "/results",
                    name: 'results',
                    component: () => import("@/view/front-end/results/Index"),
                },
                {
                    path: "/forgot-enrolment-pin",
                    name: 'forgot-enrolment-pin',
                    component: () => import("@/view/front-end/results/ForgotEnrolmentPin"),
                },
                {
                    path: "/profile",
                    name: 'enrollment-profile',
                    component: () => import("@/view/front-end/dashboard/Index.vue"),
                },

            ]
        },

        // {
        //     path: "/",
        //     component: () => import("@/view/layout/Layout"),
        //     children: [
        //         {
        //             path: "/dashboard",
        //             name: 'enrollment-index',
        //             component: () => import("@/view/pages/Dashboard")
        //         },
        //
        //
        //         {
        //             path: "/enrollment/:type",
        //             name: 'enrollment-type',
        //             component: () => import("@/view/pages/enrollment/Enrollment")
        //         },
        //         {
        //             path: "/enrollment/:id/resume",
        //             name: 'enrollment-resume',
        //             component: () => import("@/view/pages/enrollment/Enrollment")
        //         },
        //
        //         {
        //             path: "/enrollment/history/all",
        //             name: 'enrollment-history',
        //             component: () => import("@/view/pages/enrollment/History")
        //         },
        //         // {
        //         //     path: "/pre-registration",
        //         //     name: 'pre-registration',
        //         //     component: () => import("@/view/pages/pre-registration/Base"),
        //         //     children: [
        //         //         {
        //         //             path: "/pre-registration/history",
        //         //             name: 'pre-registration-history',
        //         //             component: () => import("@/view/pages/pre-registration/Index")
        //         //         },
        //         //         {
        //         //             path: "/pre-registration/new",
        //         //             name: 'pre-registration-new',
        //         //             component: () => import("@/view/pages/pre-registration/CreateOrUpdate")
        //         //         },
        //         //     ],
        //         // },
        //     ],
        //
        // },


        // {
        //     path: "/",
        //     component: () => import("@/view/pages/auth/Auth"),
        //     children: [
        //         {
        //             name: "login",
        //             path: "/login",
        //             component: () => import("@/view/pages/auth/Login")
        //         },
        //         {
        //             name: "register",
        //             path: "/register",
        //             component: () => import("@/view/pages/auth/Register")
        //         },
        //         {
        //             name: "verification",
        //             path: "/verification",
        //             component: () => import("@/view/pages/auth/Verification")
        //         },
        //         {
        //             name: "forgot-password",
        //             path: "/forgot-password",
        //             component: () => import("@/view/pages/auth/ForgotPassword")
        //         },
        //         {
        //             name: "reset-password",
        //             path: "/reset-password",
        //             component: () => import("@/view/pages/auth/ResetPassword")
        //         },
        //     ]
        // },
        {
            path: "*",
            redirect: "/404"
        },
        {
            // the 404 route, when none of the above matches
            path: "/404",
            name: "404",
            component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
            // the 404 route, when none of the above matches
            path: "/email-verification/:token1/:token2",
            name: "email-verification",
            component: () => import("@/view/front-end/EmailVerification.vue")
        },

    ]
})
;
