<template>
    <router-view></router-view>
</template>

<style lang="scss">
    // 3rd party plugins css
    @import "~bootstrap-vue/dist/bootstrap-vue.css";
    @import "~perfect-scrollbar/css/perfect-scrollbar.css";
    @import "~socicon/css/socicon.css";
    //@import "~animate.css";
    @import "~@fortawesome/fontawesome-free/css/all.css";
    @import "~line-awesome/dist/line-awesome/css/line-awesome.css";
    @import "assets/plugins/flaticon/flaticon.css";
    @import "assets/plugins/flaticon2/flaticon.css";
    @import "assets/plugins/keenthemes-icons/font/ki.css";
    @import "~vue-snotify/styles/material";

    :root {
        --body-bg-color: #f3f6f9;
        --primary-color:#000;
        --secondary-color: #f2883a;
        --primary-text-color:#000;
        --secondary-text-color: #ffffff;
        --v-primary-base:#04458c !important;

        //Login
        --login-bg-color:#00458f;
        --login-primary-color:#00458f;
        --login-secondary-color:#f2883a;

        //buttons
        --button-bg-color:#00458f;
        --button-primary-color:#38d;
        --button-secondary-color:#16b;
        --button-primary-text-color:#00458f;
        --button-secondary-text-color:#f2883a;


      //Sidebar
        --sidebar-primary-color:#000000;
        --sidebar-secondary-color: #f2883a;
        --sidebar-primary-text-color:#fff;
        --sidebar-secondary-text-color: #ffffff;
        --sidebar-bg-color: #000000;
        --sidebar-active-menu-bg-color: #ffffff;
        --sidebar-active-menu-text-color: #ffffff;
        --sidebar-menu-text-color: #ffffff;

      //Sub Header
        --header-primary-color:#5d5d5d;
        --header-secondary-color: #f2883a;
        --header-primary-text-color:#ffffff;
        --header-secondary-text-color: #ffffff;

        --sub-header-bg-color: blue;
        --sub-header-text-color: blue;
        --color-white: #fff;
        --color-dark: #212121;
    }


    // Main demo style scss
    @import "assets/sass/style.vue";
    .v-application v-application--is-ltr theme--light{
        background: #fff !important;
    }
    // Check documentation for RTL css
    // Update HTML with RTL attribute at public/index.html
    /*@import "assets/css/style.vue.rtl";*/
</style>

<script>
    import {OVERRIDE_LAYOUT_CONFIG} from "@/core/services/store/config.module";

    export default {
        name: "Yelko",
        mounted() {
            /**
             * this is to override the layout config using saved data from localStorage
             * remove this to use config only from static json (@/core/config/layout.config.json)
             */
            this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
        }
    };
</script>
