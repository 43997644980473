import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {RESET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import {SET_CAPTCHA} from "@/core/services/store/config.module";
import CKEditor from "ckeditor4-vue";

Vue.use(CKEditor);
Vue.config.productionTip = false;
import VueClipboard from 'vue-clipboard2'
import Verte from 'verte';
import 'verte/dist/verte.css';

Vue.component(Verte.name, Verte);
Vue.use(Verte);
Vue.use(VueClipboard)
import DatetimePicker from 'vuetify-datetime-picker'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(DatetimePicker)
// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";

window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";

window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/inline-svg";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
import Snotify from 'vue-snotify';
import Vuelidate from "vuelidate/src";
import VueConfirmDialog from 'vue-confirm-dialog'
import VueBus from 'vue-bus';

Vue.use(VueBus);

// API service init
ApiService.init();
Vue.use(Snotify);
Vue.use(VueConfirmDialog);
Vue.use(Vuelidate);
import VNepaliDatePicker from 'v-nepalidatepicker';

Vue.use(VNepaliDatePicker);
Vue.use(require('vue-moment'));
// Remove this to disable mock API
//MockService.init();

// router.beforeEach((to, from, next) => {
//
//   // Ensure we checked auth before each page load.
//   if(to.name!='login' && to.name!='logout' && to.name!='register' && to.name!='reset-password' && to.name!='verification' && to.name!='forgot-password')
//   {
//     Promise.all([store.dispatch(VERIFY_AUTH, to)]).then(next);
//     setTimeout(() => {
//       window.scrollTo(0, 0);
//     }, 100);
//   }
//   else
//   {
//     // console.log(to.name)
//     Promise.all([to]).then(next);
//     setTimeout(() => {
//       window.scrollTo(0, 0);
//     }, 100);
//   }
//
//   // reset config to initial state
//   store.dispatch(RESET_LAYOUT_CONFIG);
//
//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });


if (!window.localStorage.getItem('XSRF-TOKEN')) {
    Vue.axios.get("/sanctum/csrf-cookie").then(() => {
        Vue.axios.get("/site-setting/colors").then((response) => {
            store.dispatch(SET_CAPTCHA, response.data);
        });
    });
}


new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount("#app");
