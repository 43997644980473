import {ADD_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";

export default {
    state: {
        credential:{},
        enrollment:{},
    },
    getters: {
        getCredential(state){
            return state.credential;
        },
        getEnrollment(state){
            return state.enrollment;
        }
    },
    actions: {
        setCredential(context, payload) {
            context.commit('setCredential', payload)
        },
        setEnrollment(context, payload) {
            context.commit('setEnrollment', payload)
        },


    },
    mutations: {
        setCredential(state, payload) {
            state.credential = payload
        },
        setEnrollment(state, payload) {
            state.enrollment = payload
        },
    },
}